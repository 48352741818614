<script>
/**
 * Email-invoice component
 */
export default {}
</script>

<template>
<!-- Hero Start -->
<section style="align-items: center; padding: 150px 0;">
    <div class="container">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 col-md-8">
                <table style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                    <thead>
                        <tr style="background-color: #2f55d4; padding: 3px 0; line-height: 68px; text-align: center; color: #fff; font-size: 24px; font-weight: 700; letter-spacing: 1px;">
                            <th scope="col"><img src="images/logo-light.png" height="24" alt=""></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td style="padding: 24px 24px 0;">
                                <table style="max-width: 350px">
                                    <tbody>
                                        <tr>
                                            <td style="min-width: 130px; padding-bottom: 8px;">Invoice No. :</td>
                                            <td style="color: #8492a6;">#land45845621</td>
                                        </tr>
                                        <tr>
                                            <td style="min-width: 130px; padding-bottom: 8px;">Name :</td>
                                            <td style="color: #8492a6;">Harry Patel</td>
                                        </tr>
                                        <tr>
                                            <td style="min-width: 130px; padding-bottom: 8px;">Address :</td>
                                            <td style="color: #8492a6;">1962 Pike Street, CA 92123</td>
                                        </tr>
                                        <tr>
                                            <td style="min-width: 130px; padding-bottom: 8px;">Date :</td>
                                            <td style="color: #8492a6;">March, 25 2020</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 24px;">
                                <div style="display: block; width: 100%; overflow-x: auto !important; border-radius: 6px; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">
                                    <table style="width: 100%">
                                        <thead class="bg-light">
                                            <tr>
                                                <th scope="col" style="text-align: left; vertical-align: bottom; border-top: 1px solid #dee2e6; padding: 12px;">No.</th>
                                                <th scope="col" style="text-align: left; vertical-align: bottom; border-top: 1px solid #dee2e6; padding: 12px;">Item</th>
                                                <th scope="col" style="text-align: end; vertical-align: bottom; border-top: 1px solid #dee2e6; padding: 12px;">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row" style="text-align: left; padding: 12px; border-top: 1px solid #dee2e6;">1</th>
                                                <td style="text-align: left; padding: 12px; border-top: 1px solid #dee2e6;">Landrick Template</td>
                                                <td style="text-align: end; padding: 12px; border-top: 1px solid #dee2e6;">$ 5200</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style="text-align: left; padding: 12px; border-top: 1px solid #dee2e6;">2</th>
                                                <td style="text-align: left; padding: 12px; border-top: 1px solid #dee2e6;">Customization</td>
                                                <td style="text-align: end; padding: 12px; border-top: 1px solid #dee2e6;">$ 3660</td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style="text-align: left; padding: 12px; border-top: 1px solid #dee2e6;">3</th>
                                                <td style="text-align: left; padding: 12px; border-top: 1px solid #dee2e6;">Development</td>
                                                <td style="text-align: end; padding: 12px; border-top: 1px solid #dee2e6;">$ 13740</td>
                                            </tr>

                                            <tr style="background-color: rgba(47, 85, 212, 0.2); color: #2f55d4; overflow-x: hidden;">
                                                <th scope="row" style="text-align: left; padding: 12px; border-top: 1px solid rgba(47, 85, 212, 0.2);">Total</th>
                                                <td colspan="2" style="text-align: end; font-weight: 700; font-size: 18px; padding: 12px; border-top: 1px solid rgba(47, 85, 212, 0.2);">$ 22600</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style="padding: 16px 8px; color: #8492a6; background-color: #f8f9fc; text-align: center;">
                                © 2019-20 Landrick.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
</template>

<style>
body {
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 400;
    overflow: visible;
}
</style>
